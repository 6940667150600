import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { User } from '../../../types';
import styled from '@emotion/styled';
import { Chip, IconButton, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-vertical.svg';
import { UserModal } from '../components/UserModal/UserModal';
import ActionsMenu from '../components/ActionsMenu';
import { DeleteUserModal } from '../components/DeleteUserModal';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<User>();

export const ActionCell = (props: CellContext<User, unknown>) => {
  const { colors } = useTheme();
  const user = props.row.original;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [editUserProfileOpen, setEditUserProfileOpen] = useState(false);
  const [deleteUserProfileOpen, setDeleteUserProfileOpen] = useState(false);

  const handleEditUserProfileOpen = useCallback(() => {
    setEditUserProfileOpen(true);
  }, []);

  const handleEditUserProfilClose = useCallback(() => {
    setEditUserProfileOpen(false);
  }, []);

  const handleDeleteUserProfileOpen = useCallback(() => {
    setDeleteUserProfileOpen(true);
  }, []);

  const handleDeleteUserProfilClose = useCallback(() => {
    setDeleteUserProfileOpen(false);
  }, []);

  return (
    <CellWrapper style={{ justifyContent: 'end', paddingRight: '12px' }}>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ svg: { g: { path: { fill: colors.icon.accent } } } }}
      >
        <MoreIcon />
      </IconButton>
      <ActionsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleEditUserProfileOpen={handleEditUserProfileOpen}
        handleDeleteUserProfileOpen={handleDeleteUserProfileOpen}
      />
      {editUserProfileOpen && (
        <UserModal
          userProfileOpen={editUserProfileOpen}
          handleUserProfilClose={handleEditUserProfilClose}
          user={user}
        />
      )}
      {deleteUserProfileOpen && (
        <DeleteUserModal
          user={user}
          toggleDeleteUser={handleDeleteUserProfilClose}
          deleteUserOpen={deleteUserProfileOpen}
        />
      )}
    </CellWrapper>
  );
};

export const useUsersTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <Typography variant='body' color={colors.text.caption}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='ID' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <AvatarCell
            name={info.getValue()}
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
          />
        ),
        header: () => <TableHeaderCell text='User' />,
        filterFn: (row, id, filterValue) => {
          const isUserNameMatched = (row.original.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          const isEmailMatched = (row.original.email ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          return isUserNameMatched || isEmailMatched;
        },
        meta: {
          width: '15%',
          maxWidth: '15%',
          minWidth: '15%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('role', {
        cell: (info) => {
          if (!info.row.original.role) return null;
          return (
            <Chip
              style={{
                backgroundColor: colors.surfaceStatus.noAction,
                minWidth: '66px',
                height: '28px',
              }}
              label={
                <Typography variant='body' color={colors.text.main}>
                  {info.row.original.role}
                </Typography>
              }
            />
          );
        },
        header: () => <TableHeaderCell text='User Type' />,
        meta: {
          width: '35%',
          maxWidth: '35%',
          minWidth: '35%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('email', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Contact' />,
        meta: {
          width: '50%',
          maxWidth: '50%',
          minWidth: '50%',
          tdStyles: { borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
