import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { Holiday, TimesheetRow } from '../../../types';
import TotalCell from '../../SingleTimesheet/components/SingleTimesheetTable/TotalCell';
import {
  addDaysToDate,
  formatDecimalNumber,
  formatUSDate,
  getDayLabel,
} from '../../../utils/formatters';

const columnHelper = createColumnHelper<TimesheetRow>();

const metaCellStyle = {
  width: '14%',
  maxWidth: '14%',
  minWidth: '14%',
  tdStyles: { paddingLeft: '16px', borderRight: `none` },
  thStyles: {
    borderRight: `none`,
  },
};

export const useAccountantSingleWeekTableColumns = (
  weekDates: string[],
  weekStart: string,
  holidays: Holiday[]
) => {
  const { colors } = useTheme();

  const rowAvg = useCallback((timesheet: TimesheetRow) => {
    return (
      (timesheet.monday +
        timesheet.tuesday +
        timesheet.wednesday +
        timesheet.thursday +
        timesheet.friday) /
      5
    );
  }, []);

  const shouldEnableLabel = useCallback(
    (dayOrder: number) => {
      return weekDates.find((weekDate) => new Date(weekDate).getDay() === dayOrder);
    },
    [weekDates]
  );

  const getDateLabel = useCallback(
    (dayOrder: number) => {
      if (weekStart) {
        const date = addDaysToDate(weekStart, dayOrder - 1);
        const formattedDate = formatUSDate(date.toISOString());
        return `${getDayLabel(dayOrder).slice(0, 3)}, ${formattedDate}`;
      }
      return '';
    },
    [weekStart]
  );

  const emptyLabel = useMemo(() => {
    if (weekDates.length !== 5 && holidays.length !== 0) return 'N/A';
    return '';
  }, [holidays, weekDates]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          const isFixedDeal = info.row.original.dealId === 0;
          return (
            <AvatarCell
              name={info.row.original.name}
              avatarBoxStyle={{
                background: isFixedDeal
                  ? colors.surfaceBackground.highlighted
                  : colors.surfaceIndicator.needsReview,
              }}
              avatarLetterStyle={{
                color: colors.text.main,
              }}
            />
          );
        },
        enableSorting: false,
        header: () => <TableHeaderCell text='Deal' />,
        footer: () => {
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                paddingLeft: '30px',
                fontSize: '17px',
              }}
            >
              Total per day
            </Typography>
          );
        },
        meta: {
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
          width: '20%',
          maxWidth: '20%',
          minWidth: '20%',
        },
      }),
      columnHelper.accessor('monday', {
        cell: (info) => (
          <Typography
            variant='h4'
            color={shouldEnableLabel(1) ? colors.text.main : colors.textAccent.disabled}
          >
            {typeof info.row.original.monday === 'number'
              ? info.row.original.monday + '%'
              : emptyLabel}
          </Typography>
        ),
        header: () => (
          <TableHeaderCell
            text={getDateLabel(1)}
            style={{
              color: shouldEnableLabel(1) ? colors.text.main : colors.textAccent.disabled,
            }}
          />
        ),
        footer: TotalCell,
        enableSorting: false,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('tuesday', {
        cell: (info) => (
          <Typography variant='h4' color={shouldEnableLabel(2) ? colors.text.main : colors.textAccent.disabled}>
            {typeof info.row.original.tuesday === 'number' ? info.row.original.tuesday + '%' : emptyLabel}
          </Typography>
        ),
        header: () => (
          <TableHeaderCell
            text={getDateLabel(2)}
            style={{
              color: shouldEnableLabel(2) ? colors.text.main : colors.textAccent.disabled,
            }}
          />
        ),
        footer: TotalCell,
        enableSorting: false,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('wednesday', {
        cell: (info) => {
          return (
            <Typography variant='h4' color={shouldEnableLabel(3) ? colors.text.main : colors.textAccent.disabled}>
              {typeof info.row.original.wednesday === 'number'
                ? info.row.original.wednesday + '%'
                : emptyLabel}
            </Typography>
          );
        },
        header: () => (
          <TableHeaderCell
            text={getDateLabel(3)}
            style={{
              color: shouldEnableLabel(3) ? colors.text.main : colors.textAccent.disabled,
            }}
          />
        ),
        footer: TotalCell,
        enableSorting: false,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('thursday', {
        cell: (info) => (
          <Typography variant='h4' color={shouldEnableLabel(4) ? colors.text.main : colors.textAccent.disabled}>
            {typeof info.row.original.thursday === 'number' ? info.row.original.thursday + '%' : emptyLabel}
          </Typography>
        ),
        header: () => (
          <TableHeaderCell
            text={getDateLabel(4)}
            style={{
              color: shouldEnableLabel(4) ? colors.text.main : colors.textAccent.disabled,
            }}
          />
        ),
        footer: TotalCell,
        enableSorting: false,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('friday', {
        cell: (info) => (
          <Typography variant='h4' color={shouldEnableLabel(5) ? colors.text.main : colors.textAccent.disabled}>
            {typeof info.row.original.friday === 'number' ? info.row.original.friday + '%' : emptyLabel}
          </Typography>
        ),
        header: () => (
          <TableHeaderCell
            text={getDateLabel(5)}
            style={{
              color: shouldEnableLabel(5) ? colors.text.main : colors.textAccent.disabled,
            }}
          />
        ),
        footer: TotalCell,
        enableSorting: false,
        meta: metaCellStyle,
      }),
      columnHelper.accessor('id', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {`${formatDecimalNumber(rowAvg(info.row.original), 2)}%`}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Total per Deal' />,
        footer: (info) => {
          return (
            <Typography variant='h4' color={colors.text.main}>
              {info.table
                .getFilteredRowModel()
                .rows.reduce((total, row) => total + Number(rowAvg(row.original)), 0) + '%'}
            </Typography>
          );
        },
        enableSorting: false,
        meta: metaCellStyle,
      }),
    ],
    [colors, rowAvg, getDateLabel, shouldEnableLabel, emptyLabel]
  );

  return columns;
};
