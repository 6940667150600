import { useAtomValue } from 'jotai';
import { ROUTES } from '../../constants/routes';
import { Unauthorized } from '../../pages/Unauthorized/Unauthorized';
import { userState } from '../../state/UIState';
import { canSeeConfig } from '../../constants/constants';

type Props = {
  component: JSX.Element;
  path: ROUTES;
};

export const GuardedRoute = ({ component: Component, path }: Props) => {
  const user = useAtomValue(userState);

  const config = canSeeConfig[user?.role as keyof typeof canSeeConfig].pages.includes(path);

  return config ? Component : <Unauthorized />;
};
