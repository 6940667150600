import styled from '@emotion/styled';
import { Box, Button, useTheme } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as ReviewIcon } from '../../../assets/icons/search_review.svg';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheet, SelectItem } from '../../../types';
import { StatusCell } from '../components/StatusCell';

import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { CheckboxComponent } from '../components/CheckboxComponent';
import { OperatingPartnerCell } from '../components/OperatingPartnerCell';
import { TsTimeframe } from '../components/TsTimeframe';
import { ROUTES } from '../../../constants/routes';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<MonthlyTimesheet>();

export const ActionCell = (props: CellContext<MonthlyTimesheet, unknown>) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const timesheet = props.row.original;

  return (
    <CellWrapper style={{ justifyContent: 'center', padding: '0 16px' }}>
      <Button
        onClick={() => {
          navigate(`/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}/${timesheet.id}`);
        }}
        variant='text'
        style={{
          height: '28px',
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        }}
        startIcon={<ReviewIcon />}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          svg: { g: { path: { fill: colors.icon.accent } } },
        }}
      >
        <Typography variant='h4' color={colors.textAccent.default} style={{ marginBottom: '-2px' }}>
          Review
        </Typography>
      </Button>
    </CellWrapper>
  );
};

export const usePendingTimesheetsTableColumns = () => {
  const { colors } = useTheme();
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <CheckboxComponent
            inHeader
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <CheckboxComponent
              row={row}
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        meta: {
          thStyles: { paddingLeft: '16px', borderRight: 'none' },
          tdStyles: { paddingLeft: '0', borderRight: 'none' },
          width: '50px',
          maxWidth: '50px',
          minWidth: '50px',
        },
      }),
      columnHelper.accessor('user', {
        cell: (info) => <OperatingPartnerCell timesheet={info.row.original} />,
        header: () => <TableHeaderCell text='Operating Partner' />,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.user?.name || '';
          const valueB = rowB.original.user?.name || '';
          return valueA.localeCompare(valueB);
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some((user) => user.id === row.original.user?.id);
        },
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '0' },
        },
      }),
      columnHelper.accessor('status', {
        cell: (info) => <StatusCell timesheet={info.row.original} />,
        header: () => <TableHeaderCell text='TS Status' />,
        meta: {
          width: '200px',
          maxWidth: '200px',
          minWidth: '200px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('month', {
        cell: (info) => <TsTimeframe info={info} />,
        header: () => <TableHeaderCell text='TS Timeframe' />,

        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;

          const filterValueDate = new Date(filterValue).getMonth();
          const cellValueDate = row.original.month - 1;

          const filterValueYear = new Date(filterValue).getFullYear();
          const cellValueYear = row.original.year;
          const isDateMatched =
            filterValueDate === cellValueDate && filterValueYear === cellValueYear;

          return isDateMatched;
        },
        meta: {
          width: '300px',
          maxWidth: '300px',
          minWidth: '300px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),

      columnHelper.accessor('approvedByOpManagers', {
        header: () => <TableHeaderCell text='Approved By' />,
        cell: (info) => (
          <Box display='flex' gap={1} overflow='scroll'>
            {(info.row.original.approvedByOpManagers ?? []).map((manager) => (
              <AvatarCell
                key={manager.id}
                avatarLetterStyle={{
                  color: colors.text.main,
                }}
                name={manager.name || ''}
              />
            ))}
          </Box>
        ),
        meta: {
          width: '300px',
          maxWidth: '300px',
          minWidth: '300px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),

      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='Action' />,
        meta: {
          width: '130px',
          minWidth: '130px',
          maxWidth: '130px',
          tdStyles: {
            paddingLeft: '0',
            borderLeft: `none`,
          },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors.text.main]
  );

  return columns;
};
