import {
  Button,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import {
  holidaySearch,
  holidaySelectedCountries,
  holidaySelectedTypes,
  holidaySelectedDate,
  holidayTable,
} from '../../../state/UIHolidayScheduleState';
import { USER_COUNTRY, HolidayTab, SelectItem, HolidayTypeItems } from '../../../types';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as ListViewIcon } from '../../../assets/icons/list-view.svg';
import { DatePicker } from '../../../components/DatePicker/DatePicker';

const countryList = [
  { id: 1, value: USER_COUNTRY.US },
  { id: 2, value: USER_COUNTRY.UK },
];

interface FilterSectionProps {
  selectedTab: HolidayTab;
  handleTabChange: (tab: HolidayTab) => void;
}

export const FilterSection = ({ selectedTab, handleTabChange }: FilterSectionProps) => {
  const { colors } = useTheme();
  const [searchValue, setSearchValue] = useAtom(holidaySearch);
  const [selectedCountries, setSelectedCountries] = useAtom(holidaySelectedCountries);
  const [selectedTypes, setSelectedTypes] = useAtom(holidaySelectedTypes);
  const [selectedDate, setSelectedDate] = useAtom(holidaySelectedDate);
  const table = useAtomValue(holidayTable);

  const onChangeSearch = useCallback(
    (value: string) => {
      if (selectedTab === HolidayTab.List) {
        table?.getColumn('name')?.setFilterValue(value);
      }
      setSearchValue(value);
    },
    [selectedTab, table, setSearchValue]
  );

  const onChangeCountry = useCallback(
    (countries: SelectItem[] | undefined) => {
      if (countries) {
        if (selectedTab === HolidayTab.List) {
          table?.getColumn('country')?.setFilterValue(countries);
        }
        setSelectedCountries(countries);
      }
    },
    [setSelectedCountries, selectedTab, table]
  );

  const onChangeType = useCallback(
    (types: SelectItem[] | undefined) => {
      if (types) {
        if (selectedTab === HolidayTab.List) {
          table?.getColumn('type')?.setFilterValue(types);
        }
        setSelectedTypes(types);
      }
    },
    [setSelectedTypes, table, selectedTab]
  );

  const onChangeDateFilter = useCallback(
    (date: Date | null) => {
      if (date) {
        const year = date.getFullYear().toString();
        setSelectedDate(year);
      } else {
        setSelectedDate('');
      }
    },
    [setSelectedDate]
  );

  const onFiltersReset = useCallback(() => {
    setSearchValue('');
    setSelectedCountries([]);
    setSelectedTypes([]);
    setSelectedDate('');
  }, [setSearchValue, setSelectedCountries, setSelectedTypes, setSelectedDate]);

  const isResetDisabled = useMemo(() => {
    return !(searchValue || selectedCountries.length || selectedTypes.length || selectedDate);
  }, [searchValue, selectedCountries, selectedTypes, selectedDate]);

  useEffect(() => {
    if (searchValue && selectedTab === HolidayTab.List) {
      table?.getColumn('name')?.setFilterValue(searchValue);
    }
  }, [searchValue, table, selectedTab]);

  useEffect(() => {
    if (selectedCountries.length && selectedTab === HolidayTab.List) {
      table?.getColumn('country')?.setFilterValue(selectedCountries);
    }
  }, [selectedCountries, table, selectedTab]);

  useEffect(() => {
    if (selectedTypes.length && selectedTab === HolidayTab.List) {
      table?.getColumn('type')?.setFilterValue(selectedTypes);
    }
  }, [selectedTypes, table, selectedTab]);

  return (
    <Container>
      <Wrapper>
        <SearchInput
          placeholder='Search'
          style={{ width: '300px', height: '36px' }}
          onClear={() => setSearchValue('')}
          onChange={(e) => onChangeSearch(e.target.value)}
          value={searchValue ?? ''}
        />
        <Multiselect
          style={{ width: '200px', marginRight: '14px' }}
          fieldPlaceholder='Select Country'
          value={selectedCountries}
          onChange={(_, countries) => onChangeCountry(countries)}
          options={countryList}
        />
        <Multiselect
          style={{ width: '200px', marginRight: '14px' }}
          fieldPlaceholder='Select Type'
          options={HolidayTypeItems}
          value={selectedTypes}
          onChange={(_, OPs) => onChangeType(OPs)}
        />
        <DatePicker
          clearable
          selectedDate={selectedDate}
          setSelectedDate={onChangeDateFilter}
          views={['year']}
          maxDate={null}
          inputFormat='yyyy'
          placeholder='Select Year'
        />
      </Wrapper>
      <Wrapper>
        <Button
          onClick={onFiltersReset}
          variant='text'
          style={{ height: '28px', marginTop: '2px' }}
          disabled={isResetDisabled}
          sx={{
            '.MuiButton-startIcon': { marginRight: '2px' },
            '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          }}
        >
          <Typography variant='subtitle1' color={colors.textAccent.default}>
            Reset Filters
          </Typography>
        </Button>
        <ToggleButtonGroup
          value={selectedTab}
          sx={{
            border: `1px solid ${colors.border.default}`,
          }}
        >
          <ToggleButton
            value={HolidayTab.Calendar}
            onClick={() => handleTabChange(HolidayTab.Calendar)}
            sx={{
              '&.Mui-selected, &.Mui-selected:hover': {
                svg: { g: { path: { fill: colors.icon.inverse } } },
                backgroundColor: colors.iconStatus.active,
              },
            }}
          >
            <CalendarIcon />
          </ToggleButton>
          <ToggleButton
            value={HolidayTab.List}
            onClick={() => handleTabChange(HolidayTab.List)}
            sx={{
              '&.Mui-selected, &.Mui-selected:hover': {
                svg: { path: { fill: colors.icon.inverse } },
                backgroundColor: colors.iconStatus.active,
              },
            }}
          >
            <ListViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Wrapper>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Wrapper = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
`;
