import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { LoadingId, User } from '../../../types';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useDeleteUser } from '../../../queries/hooks/useDeleteUser';

type Props = {
  deleteUserOpen: boolean;
  toggleDeleteUser: () => void;
  user: User;
};

export function DeleteUserModal({ user, deleteUserOpen, toggleDeleteUser }: Props) {
  const { colors } = useTheme();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const userName = user?.name;
  const { mutate: onDeleteUser, isLoading: deleteInProgress } = useDeleteUser();

  const handleDeleteUser = async () => {
    startLoading(LoadingId.deleteUser);
    onDeleteUser(user.id, {
      onSuccess: () => {
        pushSuccessToast({
          title: 'User is Deleted',
          message: `User ${userName} has been successfully deleted.`,
        });
        toggleDeleteUser();
      },
      onError: () => {
        pushErrorToast({ message: 'Failed to delete user.' });
      },
      onSettled: () => {
        stopLoading(LoadingId.deleteUser);
      },
    });
  };

  return (
    <ModalComponent
      isOpen={deleteUserOpen}
      onClose={toggleDeleteUser}
      actionButtonsProps={{
        isSubmitDisabled: deleteInProgress,
        onSubmit: handleDeleteUser,
        onCancel: toggleDeleteUser,
        submitText: 'Delete',
        submitBtnStyle: {
          background: deleteInProgress ? undefined : colors.iconStatus.error,
        },
      }}
      title={`Delete ${userName} User`}
      top='80px'
    >
      <>
        <Typography variant='body' color={colors.text.main}>
          The user profile and all containing information will be permanently deleted. Please
          confirm to proceed.
        </Typography>
        <InfoWrapper>
          <Typography variant='body' color={colors.textStatus.error}>
            {`The ${userName} Profile will be deleted.`}
          </Typography>
        </InfoWrapper>
      </>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
  margin-top: 14px;
`;
