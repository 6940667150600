import { Button, IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/icons/chevron-right.svg';
import { Typography } from '../../../../components/Typography/Typography';
import { MonthlyTimesheet, TimesheetStatus } from '../../../../types';
import { useAtomValue } from 'jotai';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../../../constants/routes';
import { useMonthlyTimesheets } from '../../../../queries/hooks/useMonthlyTimesheets';
import { userState } from '../../../../state/UIState';
import { getMonthLabel } from '../../../../utils/formatters';
import { useMonthlySingleTimesheet } from '../../../../queries/hooks/useMonthlySingleTimesheet';

type Props = {
  timesheet: MonthlyTimesheet;
};

export function TimesheetNavigation({ timesheet }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const timesheetId = useParams().id;
  const location = useLocation();

  const isAllTimesheets = location.pathname.includes(ROUTES.OP_MANAGER_ALL_TIMESHEETS);
  const isSubmittedTimesheets = location.pathname.includes(ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET);

  const currentTimesheetId = Number(timesheetId);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const navigationLabel = `${getMonthLabel(timesheet.month)} ${timesheet.year}`;

  const user = useAtomValue(userState);

  const { isLoading: allTimesheetsLoading, data: allTimesheets } = useMonthlyTimesheets({
    userId: user?.id,
  });

  const { data: singleTimesheet } = useMonthlySingleTimesheet({
    id: currentTimesheetId,
  });

  const timesheetsIds = (allTimesheets || [])
    ?.filter((ts) => {
      if (isSubmittedTimesheets) {
        return ts.status === TimesheetStatus.PENDING_OP_MANAGER;
      }
      return true;
    })
    ?.map((ts) => ts.id);

  const havePrev = currentTimesheetId !== timesheetsIds[timesheetsIds.length - 1];
  const haveNext = currentTimesheetId !== timesheetsIds[0];

  const navigateRoute = (() => {
    if (isAllTimesheets) {
      return `${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`;
    }

    if (isSubmittedTimesheets) {
      return `${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`;
    }

    return '/';
  })();

  return (
    <WeekPaginationWrap>
      <IconButton
        disabled={!havePrev || allTimesheetsLoading}
        onClick={() => {
          const currentIndex = timesheetsIds.findIndex((element) => element === currentTimesheetId);
          const id = timesheetsIds[currentIndex + 1];
          navigate(`/${navigateRoute}/${id}`);
        }}
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: havePrev ? '1' : '0.3' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
      >
        <StyledPrevIcon />
      </IconButton>
      <IconButton
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: haveNext ? '1' : '0.3' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
        disabled={!haveNext || allTimesheetsLoading}
        onClick={() => {
          const currentIndex = timesheetsIds.findIndex((element) => element === currentTimesheetId);
          const id = timesheetsIds[currentIndex - 1];
          navigate(`/${navigateRoute}/${id}`);
        }}
      >
        <StyledNextIcon />
      </IconButton>
      <Typography
        variant='body'
        color={colors.text.main}
        style={{
          lineHeight: 'inherit',
          fontSize: '1rem'
        }}
      >
        {navigationLabel}
      </Typography>
      <Button
        disabled={currentMonth === timesheet.month}
        sx={{
          '&.Mui-disabled': {
            color: colors.textAccent.disabled,
          },
        }}
        onClick={() => {
          const OPId = singleTimesheet?.user?.id;
          const currentMonthTSId = allTimesheets?.find(
            (ts) => ts.user?.id === OPId && ts.year === currentYear && ts.month === currentMonth
          )?.id;
          navigate(`/${navigateRoute}/${currentMonthTSId}`);
        }}
      >
        {currentMonth !== timesheet.month ? 'Go to current month' : 'Current month'}
      </Button>
    </WeekPaginationWrap>
  );
}

const WeekPaginationWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 30%;
`;

const StyledPrevIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

const StyledNextIcon = styled(ChevronRightIcon)``;
