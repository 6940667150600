import styled from '@emotion/styled';
import { Button, useTheme } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as ReviewIcon } from '../../../assets/icons/search_review.svg';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { ROUTES } from '../../../constants/routes';
import { SelectItem, Timesheet, TimesheetStatus, TimesheetStatusLabel } from '../../../types';
import { formatTSWeekFormat } from '../../../utils/formatters';
import DealCell from '../components/DealsCell';
import { LastEditedCell } from '../components/LastEditedCell';
import { ProxyOperatingPartnerCell } from '../components/ProxyOperatingPartnerCell';
import { StatusCell } from '../components/StatusCell';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<Timesheet>();

export const ActionCell = (props: CellContext<Timesheet, unknown>) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const timesheet = props.row.original;
  const notIssuedStatus =
    props.row.original.status !== TimesheetStatus.ISSUED &&
    props.row.original.status !== TimesheetStatus.REJECTED;
  return (
    <CellWrapper style={{ justifyContent: 'start', padding: '0 16px', width: '100%' }}>
      <Button
        onClick={() => {
          navigate(`/${ROUTES.ALL_TIMESHEETS}/${timesheet.id}`);
        }}
        variant='text'
        style={{
          width: '100%',
          height: '28px',
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        }}
        startIcon={<ReviewIcon />}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          svg: { g: { path: { fill: colors.icon.accent } } },
        }}
      >
        <Typography variant='h4' color={colors.textAccent.default} style={{ marginBottom: '-2px' }}>
          {notIssuedStatus ? 'View' : 'Review'}
        </Typography>
      </Button>
    </CellWrapper>
  );
};

export const useProxyAllTimesheetsTableColumns = () => {
  const { colors } = useTheme();
  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        cell: (info) => <ProxyOperatingPartnerCell timesheet={info.row.original} />,
        header: () => <TableHeaderCell text='Operating Partner' />,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.user?.name || '';
          const valueB = rowB.original.user?.name || '';
          return valueA.localeCompare(valueB);
        },
        meta: {
          width: '350px',
          minWidth: '350px',
          maxWidth: '350px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '0' },
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some((user) => user.id === row.original.user?.id);
        },
      }),

      columnHelper.accessor('status', {
        cell: (info) => <StatusCell status={info.getValue() as TimesheetStatusLabel} />,
        header: () => <TableHeaderCell text='TS Status' />,
        meta: {
          width: '150px',
          minWidth: '150px',
          maxWidth: '150px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('weekStart', {
        cell: (info) => {
          const cellValue = formatTSWeekFormat(
            info.row.original.weekDates[0],
            info.row.original.weekDates[info.row.original.weekDates.length - 1]
          );
          return (
            <Typography variant='body' color={colors.text.caption}>
              {cellValue}
            </Typography>
          );
        },
        header: () => <TableHeaderCell text='Week' />,

        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;

          const filterValueDate = new Date(filterValue).getMonth() + 1;
          const cellValueDate = row.original.weekDates.length
            ? new Date(row.original.weekDates[0]).getMonth() + 1
            : row.original.holidays[0].month;

          const filterValueYear = new Date(filterValue).getFullYear();
          const cellValueYear = row.original.weekDates.length
            ? new Date(row.original.weekDates[0]).getFullYear()
            : row.original.holidays[0].year;

          const isDateMatched =
            filterValueDate === cellValueDate && filterValueYear === cellValueYear;

          return isDateMatched;
        },
        meta: {
          width: '250px',
          minWidth: '250px',
          maxWidth: '250px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('timesheetRows', {
        cell: (info) => <DealCell deals={info.row.original.timesheetRows} />,
        header: () => <TableHeaderCell text='Deals' />,
        enableSorting: false,
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;
          const deals = row.original.timesheetRows;
          const hasDealMatch = deals.some((deal) =>
            deal.name.toLocaleLowerCase().includes(filterValue.toLowerCase())
          );
          return hasDealMatch;
        },
      }),
      columnHelper.accessor('updated_at', {
        cell: (info) => (
          <LastEditedCell
            updated_at={info.row.original.updated_at}
            updatedBy={info.row.original.updatedBy}
          />
        ),
        header: () => <TableHeaderCell text='Last Edited' />,
        meta: {
          width: '250px',
          minWidth: '250px',
          maxWidth: '250px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='Action' />,
        meta: {
          width: '130px',
          minWidth: '130px',
          maxWidth: '130px',
          tdStyles: {
            paddingLeft: '0',
            borderLeft: `none`,
          },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors.text.caption]
  );

  return columns;
};
