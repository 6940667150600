import { Box, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { FormField } from '../../../../components/FormField/FormField';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';

import { UpdateUserSkeletonLoader } from '../../../../components/SkeletonLoader/UpdateUser.SkeletonLoader';
import { SelectItem } from '../../../../types';
import { IUserFileds } from './UserModal';
import { useDeals } from '../../../../queries/hooks/useDeals';
import { Switch } from '../../../../components/SwitchButton/Switch';
import { Typography } from '../../../../components/Typography/Typography';

export function DealOwnerFields() {
  const { colors } = useTheme();
  const { data: dealOptionsRaw, isLoading: dealOptionsLoading } = useDeals();

  const { setFieldValue, values, touched, errors, setFieldTouched } =
    useFormikContext<IUserFileds>();
  const handleError = (field) => (touched[field] ? errors[field] : '');

  const dealsOptions: SelectItem[] = useMemo(() => {
    if (!dealOptionsRaw) return [];
    return dealOptionsRaw?.map((deal) => {
      return {
        ...deal,
        id: deal?.id,
        value: deal?.name || '',
      };
    });
  }, [dealOptionsRaw]);

  const labelStyle = {
    color: colors.text.caption,
  };

  const loadingFieldsOptions = dealOptionsLoading;

  return loadingFieldsOptions ? (
    <UpdateUserSkeletonLoader />
  ) : (
    <>
      <FormField
        label={'Deals (Optional)'}
        error={handleError('dealOwnerDeals')}
        labelStyle={labelStyle}
      >
        <Multiselect
          listboxStyle={{ height: '195px' }}
          options={dealsOptions}
          value={values?.dealOwnerDeals as SelectItem[]}
          onChange={(_, deals) => {
            setFieldValue('dealOwnerDeals', deals);
          }}
          onBlur={() => {
            setFieldTouched('dealOwnerDeals');
          }}
          disablePortal
          fieldPlaceholder='Type to Search Deals'
        />
      </FormField>

      <FormField label='Permissions' style={labelStyle}>
        <Box display='flex' gap={'10px'} alignItems='center'>
          <Switch
            checked={values.isAdmin}
            onChange={() => setFieldValue('isAdmin', !values.isAdmin)}
          />
          <Typography variant='body' color={colors.text.main}>
            Administration
          </Typography>
        </Box>
      </FormField>
    </>
  );
}
