import { Timesheet, TimesheetRow, TimesheetStatusLabel, UserRole } from '../../types';

const now = new Date();

export const SINGLE_TS_MOCK_DATA: Timesheet = {
  id: 0,
  created_at: now.toISOString(),
  updated_at: now.toISOString(),
  weekStart: now.toISOString(),
  weekDates: [now.toISOString(), now.toISOString(), now.toISOString(), now.toISOString(), now.toISOString()],
  status: TimesheetStatusLabel.ISSUED,
  timesheetRows: [
    {
      id: 0,
      created_at: now.toISOString(),
      updated_at: now.toISOString(),
      dealId: 18,
      name: 'Deal',
      monday: 0,
      tuesday: 100,
      wednesday: 100,
      thursday: 0,
      friday: 0,
    }
  ],
  user: {
    id: 0,
    created_at: now.toISOString(),
    updated_at: now.toISOString(),
    name: 'John Doe',
    email: 'john.doe@mail.com',
    role: UserRole.OPERATING_PARTNER,
    isAdmin: false,
  },
  holidays: []
};

export const TS_MOCK_DATA: Timesheet[] = [SINGLE_TS_MOCK_DATA];

export const SINGLE_TS_TABLE_MOCK_DATA: TimesheetRow[] = [
  {
    id: 0,
    created_at: now.toISOString(),
    updated_at: now.toISOString(),
    dealId: 0,
    name: 'Deal',
    monday: 0,
    tuesday: 100,
    wednesday: 100,
    thursday: 0,
    friday: 0,
    editDisabled: true,
  },
  {
    id: 1,
    created_at: now.toISOString(),
    updated_at: now.toISOString(),
    dealId: 0,
    name: 'Annual Leave/Vacation',
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    editDisabled: true,
  },
];

export const SINGLE_TS_TABLE_MOCK_DATA_SUBMITTED: TimesheetRow[] = [
  {
    id: 0,
    created_at: now.toISOString(),
    updated_at: now.toISOString(),
    dealId: 0,
    name: 'Deal',
    monday: 100,
    tuesday: 100,
    wednesday: 100,
    thursday: 100,
    friday: 0,
    editDisabled: true,
  },
  {
    id: 1,
    created_at: now.toISOString(),
    updated_at: now.toISOString(),
    dealId: 0,
    name: 'Annual Leave/Vacation',
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 100,
    editDisabled: true,
  },
];

