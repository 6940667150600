import { Button, IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheet } from '../../../types';
import { useAtomValue } from 'jotai';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { useMonthlyTimesheets } from '../../../queries/hooks/useMonthlyTimesheets';
import { userState } from '../../../state/UIState';
import { getMonthLabel } from '../../../utils/formatters';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';
import { useMemo } from 'react';

type Props = {
  timesheet: MonthlyTimesheet;
};

export function TimesheetNavigation({ timesheet }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const timesheetId = useParams().id;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const { isLoading: allOpPartnersLoading, data: allOpPartners } = useAllUserOperatingPartners(
    yearQueryParam || ''
  );
  const { data: currentYearAllOpPartners } = useAllUserOperatingPartners(currentYear.toString());

  const allTimesheetsData = (allOpPartners || [])?.find((user) =>
    user.monthlyTimesheets?.find((ts) => ts.id === Number(timesheetId))
  );
  const currentYearAllTimesheetsData = (currentYearAllOpPartners || [])?.find(
    (user) => user.monthlyTimesheets
  );

  const allOpPartnersIds = allTimesheetsData?.monthlyTimesheets?.map((ts) => ts.id) || [];

  const currentTimesheetId = Number(timesheetId);

  const navigationLabel = `${getMonthLabel(timesheet.month)} ${timesheet.year}`;

  const user = useAtomValue(userState);

  const { isLoading: allTimesheetsLoading, data: allTimesheets } = useMonthlyTimesheets({
    userId: user?.id,
  });

  const timesheetsIds = (allTimesheets || [])
    ?.filter((ts) => {
      return !!allOpPartnersIds.includes(ts.id);
    })
    ?.map((ts) => ts.id);

  const currentMonthTimesheetId = useMemo(
    () =>
      currentYearAllTimesheetsData?.monthlyTimesheets?.find((ts) => ts.month === currentMonth)?.id,
    [currentYearAllTimesheetsData, currentMonth]
  );

  const havePrev = currentTimesheetId !== timesheetsIds[timesheetsIds.length - 1];
  const haveNext = currentTimesheetId !== timesheetsIds[0];

  return (
    <WeekPaginationWrap>
      <IconButton
        disabled={!havePrev || allTimesheetsLoading || allOpPartnersLoading}
        onClick={() => {
          const currentIndex = timesheetsIds.findIndex((element) => element === currentTimesheetId);
          const id = timesheetsIds[currentIndex + 1];
          const queryParam = yearQueryParam ? `?year=${yearQueryParam}` : '';

          navigate(`/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}/${id}${queryParam}`);
        }}
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: havePrev ? '1' : '0.3' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ":hover": {
            backgroundColor: colors.surfaceBackground.highlighted,
          }
        }}
      >
        <StyledPrevIcon />
      </IconButton>
      <IconButton
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: haveNext ? '1' : '0.3' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ":hover": {
            backgroundColor: colors.surfaceBackground.highlighted,
          }
        }}
        disabled={!haveNext || allTimesheetsLoading}
        onClick={() => {
          const currentIndex = timesheetsIds.findIndex((element) => element === currentTimesheetId);
          const id = timesheetsIds[currentIndex - 1];
          const queryParam = yearQueryParam ? `?year=${yearQueryParam}` : '';

          navigate(`/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}/${id}${queryParam}`);
        }}
      >
        <StyledNextIcon />
      </IconButton>
      <Typography
        variant='body'
        color={colors.text.main}
        style={{
          lineHeight: 'inherit',
          fontSize: '1rem',
        }}
      >
        {navigationLabel}
      </Typography>
      <Button
        disabled={currentMonth === timesheet.month}
        sx={{
          '&.Mui-disabled': {
            color: colors.textAccent.disabled,
          },
        }}
        onClick={() => {
          const queryParam = yearQueryParam ? `?year=${currentYear}` : '';
          navigate(
            `/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}/${currentMonthTimesheetId}${queryParam}`
          );
        }}
      >
        {currentMonth !== timesheet.month ? 'Go to current month' : 'Current month'}
      </Button>
    </WeekPaginationWrap>
  );
}

const WeekPaginationWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 30%;
`;

const StyledPrevIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

const StyledNextIcon = styled(ChevronRightIcon)``;
