import { Box, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormField } from '../../../../components/FormField/FormField';
import { IUserFileds } from './UserModal';
import { Switch } from '../../../../components/SwitchButton/Switch';
import { Typography } from '../../../../components/Typography/Typography';

export function AccountantFields() {
  const { colors } = useTheme();

  const { setFieldValue, values } = useFormikContext<IUserFileds>();

  const labelStyle = {
    color: colors.text.caption,
  };

  return (
    <>
      <FormField label='Permissions' style={labelStyle}>
        <Box display='flex' gap={'10px'} alignItems='center'>
          <Switch
            checked={values.isAdmin}
            onChange={() => setFieldValue('isAdmin', !values.isAdmin)}
          />
          <Typography variant='body' color={colors.text.main}>
            Administration
          </Typography>
        </Box>
      </FormField>
    </>
  );
}
