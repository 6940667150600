import { AddUserPayload, EditUserPayload, UserRole } from '../../../../types';
import { IUserFileds } from './UserModal';

export const createAddUserPayload = ({
  name,
  email,
  managers,
  operatingPartnerDeals,
  role,
  isAdmin,
  operatingPartners,
  dealOwnerDeals,
  proxyOperatingPartners,
  proxy,
  startDate,
  endDate,
  country,
}: IUserFileds): AddUserPayload => {
  if (role === UserRole.OPERATING_PARTNER) {
    return {
      name,
      email,
      role,
      isAdmin,
      operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
      operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
      proxy: Number(proxy?.id),
      startDate,
      endDate,
      country,
    };
  }
  if (role === UserRole.PROXY) {
    return {
      name,
      email,
      role,
      isAdmin,
      proxyOperatingPartners: proxyOperatingPartners?.map((partner) => Number(partner.id)),
    };
  }
  if (role === UserRole.OP_MANAGER) {
    return {
      name,
      email,
      role,
      isAdmin,
      operatingPartners: operatingPartners?.map((partner) => Number(partner.id)),
    };
  }
  if (role === UserRole.DEAL_OWNER) {
    return {
      name,
      email,
      role,
      isAdmin,
      dealOwnerDeals: dealOwnerDeals?.map((deal) => Number(deal.id)),
    };
  }
  if (role === UserRole.ACCOUNTANT) {
    return {
      name,
      email,
      role,
      isAdmin,
    };
  }
  return {
    name,
    email,
    role,
    isAdmin,
    operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
    operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
    proxy: Number(proxy?.id),
  };
};

export const createEditUserPayload = ({
  managers,
  operatingPartnerDeals,
  role,
  isAdmin,
  operatingPartners,
  dealOwnerDeals,
  proxyOperatingPartners,
  proxy,
  startDate,
  endDate,
  country,
}: IUserFileds): EditUserPayload => {
  if (role === UserRole.OPERATING_PARTNER) {
    return {
      role,
      isAdmin,
      operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
      operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
      proxy: Number(proxy?.id),
      startDate,
      endDate,
      country,
    };
  }
  if (role === UserRole.PROXY) {
    return {
      role,
      isAdmin,
      proxyOperatingPartners: proxyOperatingPartners?.map((partner) => Number(partner.id)),
    };
  }
  if (role === UserRole.OP_MANAGER) {
    return {
      role,
      isAdmin,
      operatingPartners: operatingPartners?.map((partner) => Number(partner.id)),
    };
  }
  if (role === UserRole.DEAL_OWNER) {
    return {
      role,
      isAdmin,
      dealOwnerDeals: dealOwnerDeals?.map((deal) => Number(deal.id)),
    };
  }
  if (role === UserRole.ACCOUNTANT) {
    return {
      role,
      isAdmin,
    };
  }
  return {
    role,
    isAdmin,
    operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
    operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
    proxy: Number(proxy?.id),
  };
};
