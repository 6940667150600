import {
  calcTotalPerDay,
  calcTotalPerWeekday,
  calcTotalWeekdayPerDeal,
  calcWeekdayTotal,
} from '../pages/AccountantSingleTimesheet/hooks/useTimesheetCalculations';
import {
  AccountantTSView,
  MonthlyTimesheet,
  MonthlyTimesheetRow,
  MonthlyTimesheetToExport,
  TimesheetRow,
  WeeklyTimesheet,
} from '../types';
import {
  addDaysToDate,
  formatDecimalNumber,
  formatUSDate,
  getDayLabel,
  getMonthLabelShort,
  getTodaysDate,
} from './formatters';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const rowAvg = (timesheet: TimesheetRow) => {
  return (
    (timesheet.monday +
      timesheet.tuesday +
      timesheet.wednesday +
      timesheet.thursday +
      timesheet.friday) /
    5
  );
};

function calcTotal(weeklyTimesheet: WeeklyTimesheet) {
  return weeklyTimesheet.timesheetRows.reduce((acc, row) => acc + rowAvg(row), 0) + '%';
}

export const generateBulkCSVTemplate = (
  monthlyTS: MonthlyTimesheetToExport,
  selectedTab: AccountantTSView
) => {
  return monthlyTS.timesheets.flatMap((timesheet, i) => {
    const timesheetYear = timesheet.year;

    let headers: string[][] = [
      ['Date Generated:', getTodaysDate()],
      ['View:', selectedTab === AccountantTSView.week ? 'by Week' : 'by Weekday'],
      ['OP:', timesheet.user?.name ?? ''],
      ['Period:', `${getMonthLabelShort(timesheet.month)}, ${timesheetYear}`],
    ];

    if (i !== 0) {
      headers = [
        ['OP:', timesheet.user?.name ?? ''],
        ['Period:', `${getMonthLabelShort(timesheet.month)}, ${timesheetYear}`],
      ];
    }

    if (selectedTab === AccountantTSView.week) {
      return [...headers, ...csvWeekTable(timesheet), []];
    }
    return [...headers, [], ...csvWeekdayTable(timesheet)];
  });
};

export const generateMonthCSVTemplate = (timesheets: MonthlyTimesheetToExport[]) => {
  const OPNames = timesheets.map((ts) => ts.name);
  const headers = [
    ['Date Generated:', getTodaysDate()],
    ['View:', 'by Month'],
    ['OP:', OPNames.join(', ')],
  ];

  return [
    ...headers,
    [],
    ...timesheets.flatMap((monthlyTS) => csvMonthTable(monthlyTS.name, monthlyTS.timesheets)),
  ];
};

const getDateLabel = (dayOrder: number, weekStart: string) => {
  if (weekStart) {
    const date = addDaysToDate(weekStart, dayOrder - 1);
    const formattedDate = formatUSDate(date.toISOString());
    return `${getDayLabel(dayOrder).slice(0, 3)} ${formattedDate}`;
  }
  return '';
};

export const csvWeekTable = (monthlyTS: MonthlyTimesheet) => {
  return [
    ...monthlyTS.weeklyTimesheets.flatMap((weeklyTimesheet, i) => {
      return [
        ['', '', '', '', '', '', ''],
        [`Week ${i + 1}`, '', '', '', '', '', ''],
        [
          'Deal',
          ...weekdays.map((_, index) => `${getDateLabel(index + 1, weeklyTimesheet.weekStart)}`),
          'Total per Deal',
        ],
        ...weeklyTimesheet.timesheetRows
          .filter(
            (row) =>
              row.monday > 0 ||
              row.tuesday > 0 ||
              row.wednesday > 0 ||
              row.thursday > 0 ||
              row.friday > 0
          )
          .map((row) => [
            row.name,
            ...weekdays.map((weekday) =>
              typeof row[`${weekday.toLowerCase()}`] === 'number'
                ? row[`${weekday.toLowerCase()}`] + '%'
                : '0%'
            ),
            rowAvg(row) + '%',
          ]),
        ['Total per day', ...calcTotalPerDay(weeklyTimesheet), calcTotal(weeklyTimesheet)],
      ];
    }),
  ];
};

export const csvWeekdayTable = (monthlyTS: MonthlyTimesheet) => {
  return [
    ['Deal', ...weekdays, 'Total per Deal'],
    ...monthlyTS.timesheetRows
      .sort((a, b) => b.dealId - a.dealId)
      .map((row, i) => [
        monthlyTS.weeklyTimesheets[0]?.timesheetRows[i].name,
        calcTotalWeekdayPerDeal(i, 'monday', monthlyTS.weeklyTimesheets) + '%',
        calcTotalWeekdayPerDeal(i, 'tuesday', monthlyTS.weeklyTimesheets) + '%',
        calcTotalWeekdayPerDeal(i, 'wednesday', monthlyTS.weeklyTimesheets) + '%',
        calcTotalWeekdayPerDeal(i, 'thursday', monthlyTS.weeklyTimesheets) + '%',
        calcTotalWeekdayPerDeal(i, 'friday', monthlyTS.weeklyTimesheets) + '%',
        row.avg.toFixed(2) + '%',
      ])
      .filter(
        (_, i) =>
          calcTotalWeekdayPerDeal(i, 'monday', monthlyTS.weeklyTimesheets) > 0 ||
          calcTotalWeekdayPerDeal(i, 'tuesday', monthlyTS.weeklyTimesheets) > 0 ||
          calcTotalWeekdayPerDeal(i, 'wednesday', monthlyTS.weeklyTimesheets) > 0 ||
          calcTotalWeekdayPerDeal(i, 'thursday', monthlyTS.weeklyTimesheets) > 0 ||
          calcTotalWeekdayPerDeal(i, 'friday', monthlyTS.weeklyTimesheets)
      ),
    [
      'Total per day',
      calcTotalPerWeekday('monday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('tuesday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('wednesday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('thursday', monthlyTS.weeklyTimesheets) + '%',
      calcTotalPerWeekday('friday', monthlyTS.weeklyTimesheets) + '%',
      calcWeekdayTotal(monthlyTS.weeklyTimesheets) + '%',
    ],
    [],
  ];
};

export const csvMonthTable = (name: string, monthlyTS: MonthlyTimesheet[]) => {
  const uniqueDeals = monthlyTS
    .flatMap((e) => e.timesheetRows)
    .reduce((acc, item) => {
      if (item.dealId === 0) {
        if (!acc.some((current) => current.dealId === 0 && current.name === item.name)) {
          acc.push(item);
        }
      } else {
        if (!acc.some((current) => current.dealId === item.dealId)) {
          acc.push(item);
        }
      }
      return acc;
    }, [] as MonthlyTimesheetRow[])
    .sort((a, b) => b.dealId - a.dealId);

  const generateDealRow = (deal: MonthlyTimesheetRow) => {
    return [
      deal.name,
      ...monthlyTS.flatMap((ts) => {
        const dealById = ts.timesheetRows.find(
          (e) => e.dealId === deal.dealId && e.name === deal.name
        );
        if (!dealById) return '';
        return typeof dealById.avg === 'number' ? formatDecimalNumber(dealById.avg, 2) + '%' : '/';
      }),
    ];
  };
  if (!monthlyTS.length) {
    return [[name], ['No reports'], []];
  }
  return [
    [name],
    ['Deal', ...monthlyTS.flatMap((ts) => `${getMonthLabelShort(ts.month)} ${ts.year}`)],
    ...uniqueDeals.map((deal) => [...generateDealRow(deal)]),
  ];
};
